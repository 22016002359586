import React from 'react'
import './styles/SideBar.css'
import {ReactComponent as CerrarIcon} from '../images/Cerrar.svg'

export default class SideBar extends React.Component{
    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(){
        this.props.onClose()
    }
    
    render(){
        return (
            <aside className={this.props.show ? '' : 'hidden'}>
                <div className="aside-container">
                    <button className='btn' onClick={this.handleClick}>
                        <CerrarIcon/>
                    </button>
                    <div className="aside-title-block">
                        <h4 className='fw-normal'>{this.props.title}</h4>
                    </div>
                    <div className="aside-content-block">
                        {this.props.children}
                    </div>
                </div>                
            </aside>
        )
    }
}
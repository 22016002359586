import axios from 'axios'
import Config from './Config'

const url = `${Config.url}/cotizacion`

export default class Cotizacion{

    static async add(cotizacion, id_negocio){
        const response = await axios.post(url, {cotizacion, id_negocio})
        return response.data
    }

    static async getByIdNegocio(id_negocio){
        const response = await axios.get(`${url}?id_negocio=${id_negocio}`)
        return response.data
    }

    static async descarga(id_cotizacion){
        const response = await axios.get(`${url}/descarga?id_cotizacion=${id_cotizacion}`)
        return response.data
    }

    static async aprobar(id_cotizacion){
        const response = await axios.post(`${url}/aprobar`, {id_cotizacion})
        return response.data
    }

    static async rechazar(id_cotizacion){
        const response = await axios.post(`${url}/declinar`, {id_cotizacion})
        return response.data
    }
}
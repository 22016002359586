import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import './App.css'

import TopBar from './components/TopBar'
import NavBar from './components/NavBar'
import Content from './components/Content'
import Table from './components/Table'
import Footer from './components/Footer'
import SideBar from './components/SideBar'
import NuevoNegocio from './components/NuevoNegocio'
import CotizacionAside from './components/CotizacionAside'
import { addNotification } from './components/Notifications'
import Negocio from './services/Negocio'
import Cotizacion from './services/Cotizacion'
import Persona from './services/Persona'
import Config from './services/Config'
import Misc from './helpers/Misc'
import Docx from './helpers/Docx'
import {generateDocument} from './helpers/DocxTemplater'
import Letras from './helpers/Letras'


export default class App extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            // content: this.getContent(),
            asideTitle: 'Nuevo Negocio',
            title: 'Negocios',
            subTitle: 'Inicio | CRM | Negocios',
            buttonText: 'NUEVO NEGOCIO',
            search_word: '',
            module: {
                name: 'negocio',
                value: undefined
            },
            cotizacion: {
                state: 0,
                items: [],
                contrato: 0
            },        
            negocios: [],
            contrato: {
                visible: false
            },
            contactos: [],
            sidebar: false
        }

        this.handleFinish = this.handleFinish.bind(this)
        this.handleAddItem = this.handleAddItem.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleClickCotizar = this.handleClickCotizar.bind(this)
        this.handleClickAprobar = this.handleClickAprobar.bind(this)
        this.handleChangeTipoContrato = this.handleChangeTipoContrato.bind(this)
        this.handleChangeFechaInicio = this.handleChangeFechaInicio.bind(this)
        this.handleChangeFechaFin = this.handleChangeFechaFin.bind(this)
        this.handleChangeContacto = this.handleChangeContacto.bind(this)
        this.handleChangeFechaInicioActual = this.handleChangeFechaInicioActual.bind(this)
        this.handleChangeFechaFinActual  = this.handleChangeFechaFinActual.bind(this)
        this.handleOnClickGenerarContrato = this.handleOnClickGenerarContrato.bind(this)
        this.handleClickRow = this.handleClickRow.bind(this)
        this.handleCloseSideBar = this.handleCloseSideBar.bind(this)
        this.handleOnClickNuevo = this.handleOnClickNuevo.bind(this)
        this.handleClickNegocios = this.handleClickNegocios.bind(this)
        this.handleGenerarContrato = this.handleGenerarContrato.bind(this)
        this.handleChangePartida = this.handleChangePartida.bind(this)
        this.handleClickCerrarModal = this.handleClickCerrarModal.bind(this)
        this.handleChangeFianza = this.handleChangeFianza.bind(this)
        this.handleChangeSearchWord = this.handleChangeSearchWord.bind(this)

        this.obtenerNegocios()
    }   

    handleChangeFianza(evt){
        this.setState({
            contrato: {
                ...this.state.contrato,
                fianza: evt.target.value
            }
        })
    }
    
    handleClickCerrarModal(){
        this.setState({
            contrato: {
                visible: false
            }
        })
    }

    handleChangePartida(evt){
        this.setState({
            contrato: {
                ...this.state.contrato,
                partida: evt.target.value
            }
        })
    }

    handleClickNegocios(){
        this.setState({
            // content: this.getContent(),
            asideTitle: 'Nuevo Negocio',
            title: 'Negocios',
            subTitle: 'Inicio | CRM | Negocios',
            buttonText: 'NUEVO NEGOCIO',
            module: {
                name: 'negocio',
                value: undefined
            },
            cotizacion: {
                state: 0,
                items: [],
                contrato: 0
            },        
            negocios: [],
            contrato: {
                visible: false
            },
            contactos: [],
            sidebar: false
        })
        this.obtenerNegocios()
    }

    handleOnClickNuevo(){
        this.setState({
            sidebar: true,
            asideTitle: 'Nuevo Negocio',
            negocio: undefined
        })
    }

    handleCloseSideBar(){
        this.setState({
            sidebar: false
        })
    }

    handleClickRow(row){
        this.setState({
            negocio: row,
            sidebar: true,
            asideTitle: 'Negocio #' + row.id_negocio,
            cotizacion: {
                ...this.state.cotizacion
            }            
        })
    }

    async handleFinish(module, id_negocio){
        switch (module) {
            case 'cotizar':
                this.setState({
                    title: 'Nueva Cotización',
                    subTitle: '',
                    buttonText: undefined,
                    asideTitle: 'Nombre de empresa',
                    module: {
                        name: 'cotizacion',
                        value: {
                            id_negocio
                        }
                    }
                    
                })
                break;
            case 'ver-cotizacion':
                const response = await Cotizacion.getByIdNegocio(this.state.negocio.id_negocio)
                const subtotal = response.results.reduce((A, B) => A + (B.cantidad * B.precio), 0)
                this.setState({
                    title: 'Cotización #1',
                    subTitle: '',
                    buttonText: undefined,
                    asideTitle: 'Nombre de empresa',
                    module: {
                        name: 'cotizacion',
                        value: {
                            cotizacion: response.results
                        }
                    },
                    cotizacion: {
                        ...this.state.cotizacion,
                        items: response.results,
                        sub_total: subtotal.toFixed(2),
                        igv: subtotal.toFixed(2) * 0.18,
                        total: (subtotal * 1.18).toFixed(2),
                        state: response.results[0].estado + 1
                    }                    
                })
                break;
            default:
                break;
        }
        
    }

    handleSave(){
        this.obtenerNegocios()
    }

    handleChangeFechaInicio(evt){
        const d = new Date(evt.target.value)

        const date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
        const year = d.getFullYear() + 1
        const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() +1

        const nextYearDate = `${year}-${month}-${date}`

        this.setState({
            contrato: {
                ...this.state.contrato,
                fecha_inicio: evt.target.value,
                fecha_fin: nextYearDate
            }
        })
    }

    handleChangeFechaInicioActual(evt){
        const d = new Date(evt.target.value)

        const date = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate()
        const year = d.getFullYear() + 1
        const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() +1

        const nextYearDate = `${year}-${month}-${date}`

        this.setState({
            contrato: {
                ...this.state.contrato,
                fecha_inicio_actual: evt.target.value,
                fecha_fin_actual: nextYearDate
            }
        })
    }    

    async handleOnClickGenerarContrato(){
        const date_inicio = new Date(this.state.contrato.fecha_inicio)
        const date_fin = new Date(this.state.contrato.fecha_fin)
        const hoy = new Date()

        const response = await Cotizacion.getByIdNegocio(this.state.negocio.id_negocio)
        const cotizacion = response.results

        const ubicaciones = cotizacion.map(elm => {
            return {
                producto: elm.descripcion,
                ubicacion: elm.descripcion_ubicacion,
                cantidad: elm.cantidad,
                direccion: elm.direccion.capitalize()
            }
        })

        let productos = []

        cotizacion.forEach(elm => {

            let find = false

            productos.forEach((item, idx) => {
                if(elm.id_item == item.id_item){
                    productos[idx] = {
                        ...productos[idx],
                        cantidad: productos[idx].cantidad + elm.cantidad,
                        importe: ((productos[idx].cantidad + elm.cantidad) * elm.precio).toFixed(2)
                    }
                    
                    find = true
                }
            })

            if(!find){
                productos.push({
                    id_item: elm.id_item,
                    producto: elm.descripcion,
                    turno: 'Día / Noche',
                    precio: (elm.precio).toFixed(2),
                    cantidad: elm.cantidad,
                    importe: (elm.cantidad * elm.precio).toFixed(2)
                })
            }

        })

        let options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }

        const contacto = this.state.contactos.find(elm => elm.id_persona_contacto == this.state.contrato.id_contacto)

        generateDocument("https://avp.pe/wp-content/uploads/files/contrato.docx", "contrato", {
            razon_social: this.state.negocio.razon_social,
            ruc: this.state.negocio.ruc,
            domicilio: `${contacto.direccion}, DISTRITO DE ${contacto.distrito}, PROVINCIA DE ${contacto.provincia} Y DEPARTAMENTO DE ${contacto.departamento}`,
            contacto_relacion: contacto.relacion.toUpperCase(),
            contacto_nombre: contacto.nombre_completo.toUpperCase(),
            contacto_tipo_documento: contacto.abreviatura,
            contacto_documento: contacto.documento,
            partida: this.state.contrato.partida,
            ubicacion: contacto.ubicacion.toUpperCase(),
            desde: date_inicio.toLocaleDateString('es-PE', options),
            hasta: date_fin.toLocaleDateString('es-PE', options),
            hoy: hoy.toLocaleDateString('es-PE', options),
            actividad_comercial: contacto.actividad_comercial,
            fianza: this.state.contrato.fianza,
            fianza_str: Letras(this.state.contrato.fianza)
        })

        const total = productos.reduce((A, B) => A + B.importe, 0)

        let data = {
            razon_social: this.state.negocio.razon_social,
            ruc: this.state.negocio.ruc,
            domicilio: `${contacto.direccion}, DISTRITO DE ${contacto.distrito}, PROVINCIA DE ${contacto.provincia} Y DEPARTAMENTO DE ${contacto.departamento}`,
            contacto_nombre: contacto.nombre_completo.toUpperCase(),
            contacto_tipo_documento: contacto.abreviatura,
            contacto_documento: contacto.documento,
            desde: date_inicio.toLocaleDateString('es-PE', options),
            hasta: date_fin.toLocaleDateString('es-PE', options),
            hoy: hoy.toLocaleDateString('es-PE', options),
            ubicaciones,
            productos,
            distrito: cotizacion[0].distrito,
            ubicacion: cotizacion[0].direccion.capitalize() + ', ' + cotizacion[0].distrito,
            total: parseFloat(total).toFixed(2),
            anual: (total * 12).toFixed(2)
        }

        let url_anexos = "https://avp.pe/wp-content/uploads/files/anexos3.docx"

        if(this.state.contrato.tipo_contrato !== 1){
            url_anexos = "https://avp.pe/wp-content/uploads/files/anexos-adenda.docx"
            const date_inicio_actual = new Date(this.state.contrato.fecha_inicio_actual)
            const date_hasta_actual = new Date(this.state.contrato.fecha_fin_actual)

            data['desde_actual'] = date_inicio_actual.toLocaleDateString('es-PE', options)
            data['hasta_actual'] = date_hasta_actual.toLocaleDateString('es-PE', options)
        }

        generateDocument(url_anexos, "anexos", data)
    }

    handleChangeFechaFin(evt){
        this.setState({
            contrato: {
                ...this.state.contrato,
                fecha_fin: evt.target.value
            }
        })
    }

    handleChangeFechaFinActual(evt){
        this.setState({
            contrato: {
                ...this.state.contrato,
                fecha_fin_actual: evt.target.value
            }
        })
    }

    handleChangeContacto(evt){
        this.setState({
            contrato: {
                ...this.state.contrato,
                id_contacto: evt.target.value
            }
        })
    }

    handleAddItem(item){
        const subtotal = [...this.state.cotizacion.items, item].reduce((A, B) => A + (B.precio * B.cantidad), 0)
        this.setState({
            cotizacion: {
                ...this.state.cotizacion,
                items: [
                    ...this.state.cotizacion.items,
                    {
                        ...item,
                        importe: item.cantidad * item.precio
                    }
                ],
                sub_total: subtotal.toFixed(2),
                igv: subtotal.toFixed(2) * 0.18,
                total: subtotal * 1.18
            }
        })
        // console.log(item)
    }

    handleChangeTipoContrato(evt){
        this.setState({
            contrato: {
                ...this.state.contrato,
                tipo_contrato: evt.target.value
            }
        })
    }

    getAsideContent(){
        let component = <div></div>
        switch (this.state.module.name) {
            case 'negocio':
                component = <NuevoNegocio onGenerarContrato={this.handleGenerarContrato} onSave={this.handleSave} onFinish={this.handleFinish} negocio={this.state.negocio}/>
                break;

            case 'cotizacion':
                component = <CotizacionAside onAddItem={this.handleAddItem}/>
                break;
        
            default:
                break;
        }        
        return component
    }

    getContent() {
        let component = <div></div>
        switch (this.state.module.name) {
            case 'negocio':
                component = this.getNegocioContent()     
                break;

            case 'cotizacion':
                component = this.getCotizacionContent() 
                break;
        
            default:
                break;
        }
        return component
    }

    async handleClickAprobar(){
        const response = await Cotizacion.aprobar(this.state.cotizacion.id_cotizacion || this.state.cotizacion.items[0].id_cotizacion)
        if(response.complete){
            addNotification('Cotización Aprobada', 'bg-success')
            this.mostrarModalContrato()            
        }        
    }

    handleGenerarContrato(){             
        this.mostrarModalContrato()
    }

    mostrarModalContrato(){       
        this.mostrarContactos()
        this.setState({
            cotizacion: {
                ...this.state.cotizacion,
                state: 3                            
            },
            contrato: {
                visible: true,
                tipo_contrato: 1 
            }
        })
    }

    async handleClickCotizar(){
        if(this.state.cotizacion.state == 0){
            const data = {
                cotizacion: this.state.cotizacion.items,
                id_negocio: this.state.module.value.id_negocio
            }
            const response = await Cotizacion.add(data.cotizacion, data.id_negocio)
            if(response.complete){
                this.setState({
                    cotizacion: {
                        ...this.state.cotizacion,
                        state: 1,
                        id_cotizacion: response.id_cotizacion
                    }
                })
                addNotification('Cotización Registrada', 'bg-success')
            }
        }else if(this.state.cotizacion.state == 1){
            console.log(this.state.cotizacion)
            window.open(`${Config.url}/cotizacion/descarga?id_cotizacion=${this.state.cotizacion.id_cotizacion || this.state.cotizacion.items[0].id_cotizacion}`, '_blank')
            // Cotizacion.descarga(this.state.cotizacion.id_cotizacion)
        }
    }

    async mostrarContactos(){
        // console.log(this.state);
        const response = await Persona.getContactosPorNegocio(this.state.negocio.id_negocio)
        this.setState({
            contactos: response.results
        })
    }


    getModalContrato(){
        const options = [<option key='contacto-0' value=''></option>, ...this.state.contactos.map((elm, idx) => {
            return <option key={`contacto-${idx + 1}`} value={elm.id_persona_contacto}>{elm.nombre}</option>
        })]

        if(this.state.contrato.visible){
            return(
                <div className="modal d-block fade show" style={{
                    backgroundColor: 'rgba(0,0,0,0.3)'
                }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="false">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Generar Contrato</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={this.handleClickCerrarModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group mb-2">
                                <label>Tipo de Contrato</label>
                                <select className="form-select" value={this.state.contrato.tipo_contrato} onChange={this.handleChangeTipoContrato}>
                                    <option value="1">Nuevo</option>
                                    <option value="2">Adenda</option>
                                </select>
                            </div>
                            <div className="form-group mb-2">
                                <label>Contacto</label>
                                <select className="form-select" value={this.state.contrato.id_contacto || ''} onChange={this.handleChangeContacto}>
                                   {options}
                                </select>
                            </div>
                            <div className="form-group mb-2">
                                <label>N° Partida</label>
                                <input type="text" className="form-control datepicker" value={this.state.contrato.partida || ''} onChange={this.handleChangePartida} />
                            </div>
                            <div className="form-group mb-2">
                                <label>Fianza</label>
                                <input type="number" className="form-control datepicker" value={this.state.contrato.fianza || ''} onChange={this.handleChangeFianza} />
                            </div>
                            <div className={"form-group mb-2 " + (this.state.contrato.tipo_contrato !== '2' ? 'd-none' : '')}>
                                <label>Fecha de Inicio - Contrato Vigente</label>
                                <input type="date" className="form-control datepicker" placeholder="dd-mm-aaaa" value={this.state.contrato.fecha_inicio_actual || ''} onChange={this.handleChangeFechaInicioActual} />
                            </div>
                            <div className={"form-group mb-2 " + (this.state.contrato.tipo_contrato !== '2' ? 'd-none' : '')}>
                                <label>Fecha de Fin - Contrato Vigente</label>
                                <input type="date" className="form-control datepicker" placeholder="dd-mm-aaaa" value={this.state.contrato.fecha_fin_actual || ''} onChange={this.handleChangeFechaFinActual} />
                            </div>
                            <div className="form-group mb-2">
                                <label>Fecha de Inicio</label>
                                <input type="date" className="form-control datepicker" placeholder="dd-mm-aaaa" min={Misc.getDate()} value={this.state.contrato.fecha_inicio || ''} onChange={this.handleChangeFechaInicio} />
                            </div>
                            <div className="form-group mb-2">
                                <label>Fecha de Fin</label>
                                <input type="date" className="form-control datepicker" placeholder="dd-mm-aaaa" min={Misc.getDate()} value={this.state.contrato.fecha_fin || ''} onChange={this.handleChangeFechaFin} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-primary" data-bs-dismiss="modal" onClick={this.handleClickCerrarModal}>Cerrar</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleOnClickGenerarContrato}>Generar</button>
                        </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return ''
        }
        
    }

    getCotizacionContent(){
        const headers = [
            {content: 'Ubicación'}, 
            {content: 'Cantidad'}, 
            {content: 'Producto'},
            {content: 'Precio U.'},
            {content: 'Importe'}
        ]
        let btnAprobar = ''      

        if(this.state.cotizacion.state == 1){
            btnAprobar = <button className="btn btn-lg btn-primary me-2" onClick={this.handleClickAprobar}>APROBAR</button>        
        }

        const content = (
            <div>                
                <Table headers={headers} dataPk={'idx'} data={this.state.cotizacion.items} cellKeys={['descripcion_ubicacion', 'cantidad', 'abreviatura', 'precio', 'importe']} />
                <div className="position-fixed bottom-0 start-0 w-100  mb-4" style={{
                        padding: "0 340px 0 410px"                        
                    }}>
                    <div className="bg-white px-3 py-2 d-flex justify-content-between align-items-end" style={{
                        boxShadow: "0px 3px 30px rgba(0, 0, 0, 0.04)"
                    }}>
                        <div className="d-flex">
                            <div className="form-group me-2">
                                <label>SUB TOTAL</label>
                                <input type="number" className="form-control" placeholder="" value={this.state.cotizacion.sub_total || ''} disabled={true} />
                            </div>
                            <div className="form-group me-2">
                                <label>I.G.V.</label>
                                <input type="number" className="form-control" placeholder="" value={this.state.cotizacion.igv || ''} disabled={true} />
                            </div>
                            <div className="form-group me-2">
                                <label>TOTAL</label>
                                <input type="number" className="form-control" placeholder="" value={this.state.cotizacion.total || ''} disabled={true} />
                            </div>
                        </div>
                        <div>
                            {btnAprobar}
                            <button className="btn btn-lg btn-primary" onClick={this.handleClickCotizar}>{this.state.cotizacion.state !== 0 ? 'DESCARGAR' : 'COTIZAR'}</button>        
                        </div>                        
                    </div>
                                              
                </div>
            </div>            
        )

        return content
        // return <div></div>
    }

    getNegocioContent(){
        const headers = [{content: 'Código'}, {content: 'Cliente'}, {content: 'Fecha de Registro'},{content: 'Etapa'}]
        const data = this.state.negocios.filter(elm => 
            elm.etapa.toUpperCase().includes(this.state.search_word.toUpperCase()) ||
            elm.razon_social.toUpperCase().includes(this.state.search_word.toUpperCase()) ||
            elm.fecha_registro.toUpperCase().includes(this.state.search_word.toUpperCase())
        )
        const content = <Table onClickRow={this.handleClickRow} headers={headers} dataPk={'id_negocio'} data={data} cellKeys={['id_negocio', 'razon_social', 'fecha_registro', {type: 'badge', key:'etapa'}]} />

        return content
    }

    async obtenerNegocios(){
        const response = await Negocio.get()
        this.setState({
            negocios: response.results
        })
    }

    handleChangeSearchWord(evt){
        this.setState({
            search_word: evt.target.value
        })
    }

    render() {
        const footer =  this.state.module.name === 'cotizacion' ? '' : <Footer /> 
        return (
            <div className="side-show">
                {this.getModalContrato()}
                <NavBar onClickNegocios={this.handleClickNegocios} />
                <SideBar title={this.state.asideTitle} onClose={this.handleCloseSideBar} show={this.state.sidebar}>
                    {this.getAsideContent()}
                </SideBar>
                <TopBar/>
                <Content
                    sidebar={this.state.sidebar}
                    title={this.state.title}
                    subTitle={this.state.subTitle}
                    buttonText={this.state.buttonText}
                    onClick={this.handleOnClickNuevo}
                    search_word={this.state.search_word}
                    onChangeSearchWord={this.handleChangeSearchWord}
                >
                    {this.getContent()}
                </Content>                
                { footer }
            </div>
        )
    }
}
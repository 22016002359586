import React from 'react'
import './Auth.css'

import LoginContainer from './components/Login/LoginContainer'

export default class Auth extends React.Component{
    render() {
        return (
            <LoginContainer/>
        )
    }
}
import axios from 'axios'
import Config from './Config'

const url = `${Config.url}/auth`

export default class Auth {

    static login(usuario){
        return new Promise(async (resolve, reject) => {
            try{
                const response = await axios.post(`${url}/login`, usuario)
                localStorage.setItem('id_usuario', response.data.id_usuario)
                localStorage.setItem('nombre', response.data.nombre)
                resolve(response.data)
            }catch(err){
                reject(err)
            }            
        })        
    }
    
}
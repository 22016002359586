import React from 'react'
import RocketImg from '../../images/rocket.png'

export default class Login extends React.Component{
    render(){
        return (
            <form className='login-component' onSubmit={this.props.onSubmit}>
                <div className='login'>
                    <div className='login_title'>
                        <span>Iniciar Sesión</span>
                    </div>
                    <div className='login_fields'>
                        <div className='login_fields__user'>
                            <div className='icon'>
                                <img src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/user_icon_copy.png' />
                            </div>
                            <input placeholder='Correo Electronico' type='email' value={this.props.email} onChange={this.props.onChangeEmail} required={true}/>
                        </div>
                        <div className='login_fields__password'>
                            <div className='icon'>
                                <img src='https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/lock_icon_copy.png' />
                            </div>
                            <input placeholder='Contraseña' type='password' value={this.props.password} onChange={this.props.onChangePassword} required={true} />
                        </div>
                        <div className='login_fields__submit'>
                            <input type='submit' value='Ingresar' />
                            {/* <div className='forgot'>
                                <a href='#'>Forgotten password?</a>
                            </div> */}
                        </div>
                    </div>
                    <div className='disclaimer'>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce semper laoreet placerat. Nullam semper auctor justo, rutrum posuere odio vulputate nec.</p>
                    </div>
                </div>
            </form>
        )
    }
}